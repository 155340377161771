// axios.js
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSuccess, getUserFail, updateAccessToken, logoutUser } from "../../redux/slices/userSlice";

// Create a custom Axios instance for authentication-related requests
const axiosAuthService = axios.create({
    baseURL: "https://sys.tinkerspace.mis.mapit.ai/api/auth/",
});

// Create a general Axios instance for API calls
const axiosApiService = axios.create({
    baseURL: "https://sys.tinkerspace.mis.mapit.ai/api/",
});

// Create an Axios instance for image fetching (if necessary)
const ImageBaseURL = axios.create({
    baseURL: 'https://sys.tinkerspace.mis.mapit.ai',
});

// Track interceptor IDs to prevent multiple attachments
let authRequestInterceptor;
let authResponseInterceptor;
let apiRequestInterceptor;
let apiResponseInterceptor;

/**
 * Thunk Action: logout
 * Handles logging out the user by clearing sessionStorage, resetting Redux state, and redirecting to login.
 */
export const logout = () => (dispatch) => {
    // Clear tokens from sessionStorage
    sessionStorage.removeItem('authorization');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('TL_name');

    // Dispatch the logoutUser action to reset Redux state
    dispatch(logoutUser());

    // Redirect the user to the login page
    window.location.href = '/login'; // Replace '/login' with your actual login route
};

/**
 * Function: refreshToken
 * Attempts to refresh the access token using the refresh token.
 * If successful, updates the access token in Redux and sessionStorage.
 * If failed, dispatches the logout action.
 */
const refreshToken = async (dispatch, refresh) => {
    try {
        const response = await axiosAuthService.post("refresh/", { refresh });
        const { access } = response.data;

        // Update access token in state and session storage
        dispatch(updateAccessToken(access));
        sessionStorage.setItem('authorization', access);

        return access;
    } catch (error) {
        console.error("Refresh token failed", error);
        // Dispatch the logout thunk to clear tokens and reset state
        dispatch(logout());
        return null;
    }
};

/**
 * Function: setupInterceptors
 * Sets up Axios interceptors for request and response to handle token attachment and refresh logic.
 */
const setupInterceptors = (dispatch) => {
    // Eject existing interceptors if they exist to prevent multiple attachments
    if (authRequestInterceptor !== undefined) {
        axiosAuthService.interceptors.request.eject(authRequestInterceptor);
    }
    if (authResponseInterceptor !== undefined) {
        axiosAuthService.interceptors.response.eject(authResponseInterceptor);
    }
    if (apiRequestInterceptor !== undefined) {
        axiosApiService.interceptors.request.eject(apiRequestInterceptor);
    }
    if (apiResponseInterceptor !== undefined) {
        axiosApiService.interceptors.response.eject(apiResponseInterceptor);
    }

    // Request interceptor to attach tokens
    const requestInterceptor = (config) => {
        const token = sessionStorage.getItem('authorization');
        const TL = sessionStorage.getItem('TL_name');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (TL) {
            config.headers.TL = TL;
        }

        return config;
    };

    // Response interceptor to handle 401 errors and attempt token refresh
    const responseErrorInterceptor = async (error) => {
        const originalRequest = error.config;

        // If the error is 401 Unauthorized and the request has not been retried yet
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Check if the request is to the refresh token endpoint
            if (originalRequest.url.includes('refresh/')) {
                // If the refresh token request failed with 401, logout
                dispatch(logout());
                return Promise.reject(error);
            }

            // Proceed to try to refresh the token
            const refresh = sessionStorage.getItem('refresh_token');

            if (refresh) {
                const newToken = await refreshToken(dispatch, refresh);
                if (newToken) {
                    // Update the Authorization header and retry the original request
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                } else {
                    // If refreshing the token failed, logout
                    dispatch(logout());
                    return Promise.reject(error);
                }
            } else {
                // No refresh token available, logout
                dispatch(logout());
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    };

    // Attach interceptors and store their IDs
    authRequestInterceptor = axiosAuthService.interceptors.request.use(
        requestInterceptor,
        (error) => Promise.reject(error)
    );
    authResponseInterceptor = axiosAuthService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );

    apiRequestInterceptor = axiosApiService.interceptors.request.use(
        requestInterceptor,
        (error) => Promise.reject(error)
    );
    apiResponseInterceptor = axiosApiService.interceptors.response.use(
        (response) => response,
        responseErrorInterceptor
    );
};

/**
 * Hook: useAxiosInterceptors
 * Initializes Axios interceptors based on the current user state.
 */
export const useAxiosInterceptors = (setIsInterceptorReady) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user || !user.access) {
            setIsInterceptorReady(true); // Set the interceptor as ready if no user is present
            return;
        }

        const token = user.access;
        const refresh = user.refresh;
        const TL = user.user?.TL_name;

        if (token) {
            sessionStorage.setItem("authorization", token);
        }

        if (refresh) {
            sessionStorage.setItem("refresh_token", refresh);
        }

        if (TL) {
            sessionStorage.setItem("TL_name", TL);
        }

        setupInterceptors(dispatch);

        // Indicate that the interceptors are ready
        setIsInterceptorReady(true);
    }, [user, dispatch, setIsInterceptorReady]);
};

/**
 * Thunk Action: loginUser
 * Handles user login by sending credentials, storing tokens, updating Redux state, and setting up interceptors.
 */
export const loginUser = (userData) => async (dispatch) => {
    try {
        const response = await axiosAuthService.post('login/', userData);
        const { access: token, refresh, TL_name, user } = response.data;

        // Store the tokens and TL name in session storage
        sessionStorage.setItem('authorization', token);
        sessionStorage.setItem('refresh_token', refresh);
        sessionStorage.setItem('TL_name', TL_name);

        // Dispatch the getUserSuccess action with the response data
        dispatch(getUserSuccess({ access: token, refresh, user }));

        // Set up Axios interceptors
        setupInterceptors(dispatch);
    } catch (error) {
        console.error("Login failed", error);
        // Extract error message from response or use a default message
        const errorMessage = error.response?.data?.message || "Login failed";
        dispatch(getUserFail(errorMessage));
    }
};

/**
 * Fetcher Function
 * A utility function for making authenticated GET requests.
 */
export const fetcher = (url) => {
    return axiosAuthService.get(url).then((res) => res.data);
};

// Export the ImageBaseURL instance as default
export default ImageBaseURL;

// Export the Axios instances for use in other parts of the application
export { axiosAuthService, axiosApiService };

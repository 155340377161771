import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Signup } from "./pages/Signup.jsx"; // Adjust the import path as necessary
import { Login } from "./pages/Login.jsx"; // Adjust the import path as necessary
import Dashboard from "./pages/dashboard.jsx"; // Adjust the import path as necessary
import ExploreTL from "./pages/exploretl.jsx"; // Adjust the import path as necessary
import Projects from "./pages/projects.jsx"; // Adjust the import path as necessary
import Records from "./pages/records.jsx"; // Adjust the import path as necessary
import Profile from "./pages/Profile.jsx"; // Adjust the import path as necessary
import { useUser } from "./UserContext"; // Adjust the import path as necessary
import RegisterPage from "pages/auth/Register.jsx";
import CommonLoginPage from "pages/auth/commonLogin.jsx";
import AdminLoginPage from "pages/auth/adminLogin.jsx";
import StudentLoginPage from "pages/auth/studentLogin.jsx";
import ProtectedRoute from "components/utils/ProtectedRoute.jsx";
import Training from "pages/training.jsx";
import { useAxiosInterceptors } from "utilities/api/axios.js";
import ReportIssue from "components/ReportIssue/ReportIssue.jsx";
import RoutesWrapper from "routes.js";
import Calendar from "components/calendar/calendar.js";
import { ToastContainer } from "react-toastify";
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';

export const App = () => {
  const { user } = useSelector((state) => state.user); // Adjusted access to user state
  const [isInterceptorReady, setIsInterceptorReady] = useState(false);

  const PostHogAppId = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

  posthog.init(PostHogAppId,
    {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'always'
    }
  )

  posthog.identify(
    user.id,
    { userID:user.id, email: user.email, name: user.username },
    {
      is_staff: user.is_staff,
      is_student: user.is_student,
      is_active: user.is_active,
      TL_Name:user.TL_name,
    }
  );

  // Custom hook to set up Axios interceptors
  useAxiosInterceptors(setIsInterceptorReady);

  // If interceptors are not set up, show a loading state or return null
  if (!isInterceptorReady) {
    return <div>Loading...</div>; // You can customize this loading state
  }

  // Render the application once interceptors are set up
  return (
    <Router>
      <ReportIssue />
      <RoutesWrapper />
      <ToastContainer />
    </Router>
  );
};
